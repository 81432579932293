import React from "react"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"
import DefaultSeparator from "./_component_default_separator"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import data from "@src/fixtures/ContentQuote.1.0.0.json"

const ContentQuotePage = () => {
  const title = "Content Quote"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <Columns>
        <Column width={{ desktop: 8, mobile: 12 }}>
          <DefaultSeparator />
          <StructuredContentRenderer>{data}</StructuredContentRenderer>
          <DefaultSeparator />
        </Column>
        <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
      </Columns>
    </Page>
  )
}

export default ContentQuotePage
